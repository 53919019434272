import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
    <Layout metaTitle="About our Ads"
        metaDescription="We collect personal information about users over time and across different websites, apps and devices when you use this website or service."
        metaKeywords=""
    >


        <div class="legal-container rich-text">
            <h2>About Our Ads</h2>
            <p><br />
            </p>
            <p>Effective Date: June 20, 2017</p>
            <div><strong>We use tracking technologies.</strong></div>
            <div>We collect personal information about users over time and across different websites, apps and devices when you use this website or service. Third parties also collect personal information this way on our sites and services. To do this, we use several common tracking tools. Our vendors and partners may also use these tools. These may include browser cookies. We, or our vendors and partners, may also use web beacons, flash cookies, and other tracking technologies.&nbsp;</div>
            <div><br />
            </div>
            <div><br />
            </div>
            <div><strong>We use tracking technologies for a variety of reasons.</strong></div>
            <div>We and our partners use tracking tools:</div>
            <div><br />
            </div>
            <div>&bull; To recognize new or past customers.</div>
            <div>&bull; To store your password if you have registered on our site.</div>
            <div>&bull; To improve our website and provide you with a better brand experience</div>
            <div>&bull; To serve you with interest-based or targeted advertising (see below for more on interest-based advertising). &nbsp;</div>
            <div>&bull; To observe your behaviors and browsing activities over time across multiple websites or other platforms. &nbsp;</div>
            <div>&bull; To better understand the interests of our website visitors.</div>
            <div><br />
            </div>
            <div><br />
            </div>
            <div><strong>We engage in interest-based advertising.</strong></div>
            <div>We and our partners display interest-based advertising using information gathered about you over time across multiple websites, devices, or other platforms. This might include apps.</div>
            <div><br />
            </div>
            <div>Interest-based advertising or &ldquo;online behavioral advertising&rdquo; includes ads served to you after you leave a website, encouraging you to return. These ads may be served after you leave our website, or after you leave the websites of third parties. They also include ads we and our partners think are relevant based on your browsing habits or online activities. These ads might be served on websites. We might serve these ads, or third parties may serve ads. They might be about our products or other companies&rsquo; products.</div>
            <div><br />
            </div>
            <div><br />
            </div>
            <div><strong>How do we gather relevant information about you for interest-based advertising?</strong></div>
            <div>To decide what is relevant to you, we and our partners use information you make available when you interact with us, our affiliates, and other third parties. This information is gathered using the tracking tools described above. For example, we or our partners might look at your purchases or browsing behaviors. We and our partners might look at these activities on our platforms or the platforms of others.&nbsp;</div>
            <div><br />
            </div>
            <div>We work with third parties who help gather this information. These third parties might link your name or email address to other information they collect. That might include past purchases made offline or online. Or, it might include online usage information.</div>
            <div><br />
            </div>
            <div><br />
            </div>
            <div><strong>You can control certain tracking tools.</strong></div>
            <div>Your browser may give you the ability to control cookies. How you do so depends on the type of cookie. Certain browsers can be set to reject browser cookies. To control flash cookies, which we may use on certain websites from time to time, you can go <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html" target="_blank" rel="noopener">here</a>. Why? Because flash cookies cannot be controlled through your browser settings. &nbsp;</div>
            <div><br />
            </div>
            <div>Our Do Not Track Policy: Some browsers have &ldquo;do not track&rdquo; features that allow you to tell a website not to track you. These features are not all uniform. We do not currently respond to those signals. If you block cookies, certain features on our sites may not work. If you block or reject cookies, not all of the tracking described here will stop.&nbsp;</div>
            <div><br />
            </div>
            <div>Certain options you select are browser and device specific.</div>
            <div><br />
            </div>
            <div><br />
            </div>
            <div><strong>You can opt-out of behavioral advertising.</strong></div>
            <div>The <em>Self-Regulatory Program for Online Behavioral Advertising</em> program provides consumers with the ability to opt-out of having their online behavior recorded and used for advertising purposes. To opt out of having your online behavior collected for advertising purposes, click <a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener">here</a>.&nbsp;</div>
            <div><br />
            </div>
            <div>Some of the tracking technologies we may use do not participate in the <em>Self-Regulatory Program for Online Behavioral Advertising</em>. You can click on the name of each tracker to get more information about how to opt-out of that tracker&rsquo;s cookies: <a href="http://www.ampush.com/privacy-policy/" target="_blank">AMP Platform</a>, <a href="http://www.appnexus.com/en/company/platform-privacy-policy#choices" target="_blank" rel="noopener">AppNexus</a>, <a href="http://www.digilant.com/privacy-policy/" target="_blank" rel="noopener">Digilant</a>, <a href="http://kenshoo.com/privacy-policy/" target="_blank" rel="noopener">Kenshoo</a>, <a href="http://www.evolvemediallc.com/privacy/" target="_blank" rel="noopener">Martini Media</a>, <a href="http://platform-cdn.sharethrough.com/privacy-policy" target="_blank" rel="noopener">Sharethrough</a>, <a href="http://www.sitescout.com/" target="_blank" rel="noopener">SiteScout</a>, <a href="http://www.spotxchange.com/privacy-policy/" target="_blank" rel="noopener">SpotXchange</a>, <a href="http://www.tremorvideo.com/privacy-policy.html" target="_blank" rel="noopener">Tremor Media</a>, <a href="http://support.twitter.com/articles/20170514" target="_blank" rel="noopener">Twitter </a>and <a href="http://www.xaxis.com/static/view/opt-out-confirmation" target="_blank">Xaxis</a>.</div>
            <div><br />
            </div>
            <div>The <em>Digital Advertising Alliance</em> also offers a tool for opting out of the collection of cross-app data on a mobile device for interest-based advertising. To exercise choices for the companies participating in this tool, download the <em>AppChoices</em> app <a href="http://youradchoices.com/appchoices" target="_blank">here</a>.</div>
            <div><br />
            </div>
            <div>Certain choices you make are both browser and device-specific.</div>
            <div><br />
            </div>
            <div>&copy; 2017 Tyson Foods, Inc. All rights reserved.</div>
            <p> </p>
        </div>



        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>
            </div>
        </article>
    </Layout>
)

export default SecondPage
